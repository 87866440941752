<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="12" md="3" sm="12">Filtri</v-col>
            <v-col cols="12" md="8" sm="12" class="text--secondary"> <!-- :cols="open ? 6 : 8"  -->
              <!-- <v-fade-transition leave-absolute> -->
                <span  v-if="open" key="0">Imposta i filtri di visualizzazione</span>
                <span v-else key="1">
                  <v-chip v-if="filtro.codice" class="mr-1">{{ filtro.codice }}</v-chip>
                  <v-chip v-if="filtro.descrizione" class="mr-1">{{ filtro.descrizione }}</v-chip>
                  <v-chip v-if="filtro.barcode" class="mr-1">{{ filtro.barcode }}</v-chip>
                  <v-chip class="mr-1" v-for="x in unionFiltri" :key="x">{{ x }}</v-chip>
                </span>
              <!-- </v-fade-transition> -->
            </v-col>
            <!-- <v-col cols="2" v-if="open"><v-switch :value="filtro.soloGiacenzaPositiva" dense hide-details inset label="Solo giacenza positiva" @click.stop="onGiacenzaPositiva"></v-switch></v-col> -->
            <v-col cols="12" md="1" xs="6" sm="6" v-if="open"><v-btn @click.stop="onFiltra">Ricerca</v-btn></v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col cols="12" md="2" xs="12" sm="12"><v-switch :value="filtro.soloGiacenzaPositiva" dense hide-details inset label="Solo giacenza positiva" @click.stop="onGiacenzaPositiva"></v-switch></v-col>          
        </v-row>
        <v-row align="center" justify="space-around" dense>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-text-field v-model="filtro.codice" dense outlined hide-details label="Codice" clearable @keydown.enter="onFiltra"  @click:clear="onClear"></v-text-field>
          </v-col>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-text-field v-model="filtro.descrizione" dense outlined hide-details label="Descrizione" clearable @keydown.enter="onFiltra"  @click:clear="onClear"></v-text-field>
          </v-col>          
        </v-row>
        <v-row align="center" justify="space-around" dense>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-select v-model="filtro.marchio" :items="marchi" dense chips small-chips flat hide-details outlined multiple label="Marchio" item-text="descrizione" item-value="codice" clearable  @click:clear="onClear"></v-select>
          </v-col>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-text-field v-model="filtro.barcode" dense hide-details outlined label="Barcode" clearable @keydown.enter="onFiltra"  @click:clear="onClear"></v-text-field>
          </v-col>   
        </v-row>        
        <v-row justify="space-around" dense>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-select v-model="filtro.categoria1" :items="categoria1" dense chips small-chips flat hide-details outlined multiple label="Categoria 1" item-text="descrizione" item-value="codice" clearable  @click:clear="onClear"></v-select>
          </v-col>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-select v-model="filtro.categoria2" :items="categoria2" dense chips small-chips flat hide-details outlined multiple label="Categoria 2" item-text="descrizione" item-value="codice" clearable  @click:clear="onClear"></v-select>
        </v-col>
        </v-row>        
        <v-row v-if="usaTagliaColore" justify="space-around" dense>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-select v-model="filtro.variante1" :items="variante1" dense chips small-chips flat hide-details outlined multiple label="Taglia" item-text="descrizione" item-value="codice" clearable  @click:clear="onClear"></v-select>
          </v-col>
          <v-col cols="12" md="5" xs="12" sm="12">
            <v-select v-model="filtro.variante2" :items="variante2" dense chips small-chips flat hide-details outlined multiple label="Colore" item-text="descrizione" item-value="codice" clearable  @click:clear="onClear"></v-select>
        </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import configurazioni from '@/services/configurazioniService.js'
  import { dynamicSortMultiple } from '@/components/helpers.js'
  export default {
    props: {
      base: String,
      usaTagliaColore: Boolean
    },
    data: () => ({
      marchi: [],
      variante1: [],
      variante2: [],
      categoria1: [],
      categoria2: [],
      filtro: {
        testo: '',
        marchio: [],
        variante1: [],
        variante2: [],
        categoria1: [],
        categoria2: [],
        soloGiacenzaPositiva: true
      }
    }),
    async mounted() {
      // passare le configurazioni da DB
      if (this.base) {
        await this.leggeConfigurazioni()
      }
    },
    watch: {
      async base(value) {
        await this.leggeConfigurazioni()
      }
    },
    computed: {
      unionFiltri() {
        const cat1 = this.categoria1.filter(x => this.filtro.categoria1.includes(x.codice)).map(x => x.descrizione)
        const cat2 = this.categoria2.filter(x => this.filtro.categoria2.includes(x.codice)).map(x => x.descrizione)
        const var1 = this.variante1.filter(x => this.filtro.variante1.includes(x.codice)).map(x => x.descrizione)
        const var2 = this.variante2.filter(x => this.filtro.variante2.includes(x.codice)).map(x => x.descrizione)
        return this.filtro.marchio.concat(cat1).concat(cat2).concat(var1).concat(var2)
      }
    },
    methods: {
      onGiacenzaPositiva() {
        this.filtro.soloGiacenzaPositiva = !this.filtro.soloGiacenzaPositiva
        this.onFiltra()
      },
      async leggeConfigurazioni() {
        this.marchi = (await this.getValues('prodotti_categorie.marchi')).sort(dynamicSortMultiple('descrizione'))
        this.variante1 = (await this.getValues('prodotti_varianti.varianti.variante1')).sort(dynamicSortMultiple('descrizione'))
        this.variante2 = (await this.getValues('prodotti_varianti.varianti.variante2')).sort(dynamicSortMultiple('descrizione'))
        this.categoria1 = (await this.getValues('prodotti_categorie.categorie.categoria1')).sort(dynamicSortMultiple('descrizione'))
        this.categoria2 = (await this.getValues('prodotti_categorie.categorie.categoria2')).sort(dynamicSortMultiple('descrizione'))
      },
      onFiltra() {
        this.$emit('ricerca', this.filtro)
      },
      async onClear() {
        await this.$nextTick()
        this.onFiltra()
      },
      async getValues(source) {
        let elementi = []
        if (typeof source === 'string') {
          const config = source.split('.')
          const configId = config[0]
          const subId = config[1] || '' // TODO verificare
          const sub2 = config[2] || ''
          const sourceArray = await configurazioni.getConfigurazione(this.base, configId, subId)
          if (sub2) {
            elementi = sourceArray ? sourceArray.filter(x => x.tipo === sub2).map(x => { return { codice: x.codice, descrizione: x.descrizione } }) : []
          } else {
            elementi = sourceArray ? sourceArray.map(x => { return { codice: x.codice, descrizione: x.descrizione } }) : []
          }
        } else if (Array.isArray(source)) {
          elementi = [...source]
        }
        return elementi
      }
    }
  }
</script>
