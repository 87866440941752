<template>
  <v-card class="mx-auto">
    <v-card-title>Riepilogo Ordine</v-card-title>
<!--       <v-toolbar-title>Riepilogo Ordine</v-toolbar-title>
    </v-toolbar> -->
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-row dense>
            <v-col cols="12">
              Cliente: <span class="text-h6">{{infoCliente}}</span>
            </v-col>
            <v-col cols="12">
              Indirizzo: <span class="font-weight-bold">{{indirizzo}}</span>
            </v-col>
            <v-col cols="12">
              Località: <span class="font-weight-bold">{{localita}}</span>
            </v-col>
            <v-col cols="12">
              email: <span class="font-weight-bold">{{email}}</span>
            </v-col>                        
          </v-row>
        </v-col>
        <v-col>
          <v-row dense>
            <v-col cols="12" class="text-h6">
              Importo ordine: {{importoOrdine | euro}}
            </v-col>
            <v-col cols="12" class="text-h6">
              Numero articoli: {{numeroItem}}
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menuConsegna"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedConsegnaFormatted"
                    label="Data ritiro"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="dataConsegna = null"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dataConsegna" no-title :min="dataMinima" scrollable @input="menuConsegna = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined hide-details label="Note" v-model="note"></v-textarea>
            </v-col>                                      
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" :disabled="disabled" @click="onCreaOrdine">Trasmetti ordine</v-btn>
      <slot name="nuovoOrdine"></slot>
      <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :onlyOk="false"
        :confirmText="confirmText"
        @dialog-confirm="onConferma"
        @dialog-cancel="dialogConferma = false"
      />
    </v-card-actions>
  </v-card>          
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import DialogConferma from '@/components/dialogConferma'
  
  export default {
    props: {
      carrello: Array,
      cliente: Object,
      disabled: Boolean,
      reset: Number
    },
    components: {
      DialogConferma
    },    
    data (vm) {
      return {
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.onCreaOrdine,
        confirmText: 'SI',        
        menuConsegna: false,
        dataConsegna: null,
        consegnaFormatted: moment().format('DD/MM/YYYY'),
        note: ''
      }
    },
    computed: {
      infoCliente() {
        if (!this.cliente) return ''
        return _.get(this.cliente, 'codice', '') + ' - ' + _.get(this.cliente, 'ragioneSociale', '')
      },
      indirizzo() {
        return _.get(this.cliente, 'recapiti[0].indirizzo', '')
      },
      localita() {
        return _.get(this.cliente, 'recapiti[0].localita', '')
      },
      email() {
        return _.get(this.cliente, 'recapiti[0].email', '')
      },
      computedConsegnaFormatted () {
        return this.formatDate(this.dataConsegna)
      },
      numeroItem() {
        return _.sumBy(this.carrello, 'appoggio.quantita', 0)
      },
      importoOrdine() {
        let importo = 0
        for (let c of this.carrello) {
          importo += c.prezzoCliente * c.appoggio.quantita
        }
        return importo
      },
      dataMinima() {
        return moment().toISOString()
      }
    },
    watch: {
      'dataConsegna' (val) {
        this.consegnaFormatted = this.formatDate(this.dataConsegna)
      },
      reset(value) {
        this.dataConsegna = null
        this.note = ''
      }
    },
    methods: {
      formatDate (date) {
        if (!date) return ''
        return moment(date).format('DD/MM/YYYY')
      },
      onCreaOrdine() {
        this.titoloConferma = 'Nuovo ordine'
        this.messaggioConferma = 'Confermi invio ordine ?'
        this.onConferma = this.creaOrdine
        this.dialogConferma = true
      },
      async creaOrdine() {
        this.dialogConferma = false
        this.$emit('invia', { dataConsegna: this.dataConsegna, note: this.note })
      }
    }
  }
</script>