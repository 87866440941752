<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="3">Filtri</v-col>
            <v-col cols="8" class="text--secondary">
              <span  v-if="open" key="0">Imposta i filtri di visualizzazione</span>
              <span v-else key="1">
                {{ filtro.testo }} {{ filtro.indirizzo }} {{ filtro.titolare }}
                <v-chip class="mr-1" v-if="filtro.isCliente">Cliente</v-chip>
                <v-chip class="mr-1" v-if="filtro.isFornitore">Fornitore</v-chip>
              </span>
            </v-col>
            <v-col cols="1" v-if="open"><v-btn @click.stop="onFiltra">Ricerca</v-btn></v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center" justify="space-around" dense>
          <v-col md="5">
            <v-text-field v-model="filtro.testo" outlined label="Codice, ragione sociale, partita iva" clearable dense hide-details @keydown.enter="onFiltra" @click:clear="onClear"></v-text-field>
          </v-col>
          <v-col md="5">
            <v-text-field v-model="filtro.indirizzo" outlined label="Indirizzo" clearable dense hide-details @keydown.enter="onFiltra" @click:clear="onClear"></v-text-field>
        </v-col>
        </v-row>
        <v-row justify="space-around" dense>
          <v-col md="5">
             <v-text-field v-model="filtro.titolare" outlined label="Informazioni titolare" clearable dense hide-details @keydown.enter="onFiltra" @click:clear="onClear"></v-text-field>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.isCliente" inset dense hide-details label="Cliente"></v-switch>
          </v-col>
          <v-col md="2">
            <v-switch v-model="filtro.isFornitore" inset dense hide-details label="Fornitore"></v-switch>
        </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  // import configurazioni from '@/services/configurazioniService.js'
  export default {
    data: () => ({
      filtro: {
        testo: '',
        indirizzo: '',
        titolare: '',
        isCliente: true,
        isFornitore: false
      }
    }),
    async mounted() {
      this.$emit('ricerca', this.filtro)
    },
    computed: {
    },
    methods: {
      onFiltra() {
        this.$emit('ricerca', this.filtro)
      },
      async onClear() {
        await this.$nextTick()
        this.onFiltra()
      }
    }
  }
</script>
