'use strict'
// TODO: Spostare su servizio generale in cloud
require('dotenv').config();
import configurazioni from './configurazioniService'
import moment from 'moment'
import PouchDB from 'pouchdb'
PouchDB.plugin(require('pouchdb-find'))

const service = process.env.VUE_APP_service_licenze || ''
var documento_default = require('../modelli/documento.json');
const defPagamento = {
  condizioni: 'TP02',
  dettaglio: {
    modalita: 'MP01'
  }
}

const creaOrdineWeb = async (base, tipoDocumento, cliente, righe, payload) => {
  const listini = await configurazioni.getConfigurazione(base, 'listini', 'listini')
  const elenco = await configurazioni.getConfigurazione(base, 'documenti_parametri', 'tipiDocumento')
  const infoTipo = elenco.find(x => x.codice === tipoDocumento)
  if (!infoTipo) return null // TODO: gestire errore e tipo
  const dataDocumento = new Date()
  const annoRiferimento = dataDocumento.getFullYear()
  const numeroDocumento = await configurazioni.getNumeratore(base, infoTipo.numeratore, true, annoRiferimento)
  let destinazione = cliente.recapiti.find(x => x.tipo.default)
  if (!destinazione) {
    destinazione = cliente.recapiti.find(x => x.tipo.codice === 'principale')
  }
  // if (!destinazione) return null // TODO: gestire errore e tipo, verificare cosa succede se bypasso errore
  const pagamento = _.get(cliente, 'amministrativi.pagamento', defPagamento) 
  let testata = {
    _id: numeroDocumento,
    codice: numeroDocumento,
    data: dataDocumento,
    tipoDocumento: infoTipo,
    anagrafica: {
      cliente: {
        codice: cliente.codice,
        descrizione: cliente.ragioneSociale,
        amministrativi: cliente.amministrativi,
        fatturazione: cliente.fatturazione
      }
    },
    pagamento: pagamento,
    spedizione: {
      destinazione: destinazione || {}
    },
    righe: []
  }
  if (payload) {
    if (payload.dataConsegna) {
      testata.note = 'Data consegna: ' + moment(payload.dataConsegna, 'YYYY-MM-DD').format('DD/MM/YYYY') + '\n'
    }
    if (payload.note) {
      testata.note = (testata.note || '') + payload.note
    }
    testata.payload = payload
  }
  for (const rr of righe) {
    // attenzione alle note valorizzate o da riportare in fattura
    const rigaDocumento = {}
    if (rr.codice && rr.codice.toLowerCase() !== 'notalibera') {
      rigaDocumento.rigaDescrittiva = false
      rigaDocumento.prodotto = {
        codice: rr.codice
      }
    } else {
      rigaDocumento.rigaDescrittiva = true
    }
    rigaDocumento.descrizione = rr.descrizione
    rigaDocumento.quantita = rr.quantita || 0
    rigaDocumento.unitaMisura = rr.unitaMisura || ''
    rigaDocumento.sconti = rr.sconti || ''
    if (rr.iva) {
      rigaDocumento.aliquotaIva = {
        codice: rr.iva.codice,
        valore: rr.iva.valore || rr.iva.aliquota,
        descrizione: rr.iva.descrizione,
        tipoApplicazione: rr.iva.tipoApplicazione || 'banco'
      }
    }
    const listino = listini.find(x => x.codice === (rr.infoPrezzo.listino || 'L1'))
    // Calcolare importi
    if (rr.prezzoUnitario) {
      rigaDocumento.valore = {
        listino: listino,
        // verificare come inserire gli sconti
        sconto1: rr.infoPrezzo.scontoP || '', // Valore percentuale
        sconto2: '',
        sconto3: '',
        sconto4: null
      }
      var pz
      const iva = +(_.get(rr, 'iva.valore', 22) / 100).toFixed(2)
      if (listino.ivato) {
        // TODO: rivedere gli arrotondamenti come fatture !!
        pz = {
          prezzoUnitario: rr.infoPrezzo.prezzoListino,
          prezzoTotale: +(rr.infoPrezzo.prezzoListino * rr.quantita).toFixed(2),
          importoUnitario: rr.prezzoUnitario,
          importoTotale: rr.prezzo
        }
        pz.prezzoNettoUnitario = +(pz.prezzoUnitario / (1 + iva)).toFixed(2),
        pz.prezzoNettoTotale = +(pz.prezzoNettoUnitario * rr.quantita).toFixed(2)
        pz.importoNettoUnitario = +(pz.importoUnitario / (1 + iva)).toFixed(2),
        pz.importoNettoTotale = +(pz.importoNettoUnitario * rr.quantita).toFixed(2)      
      } else {
        pz = {
          prezzoNettoUnitario: rr.infoPrezzo.prezzoListino,
          prezzoNettoTotale: +(rr.infoPrezzo.prezzoListino * rr.quantita).toFixed(2),
          importoNettoUnitario: rr.prezzoUnitario,
          importoNettoTotale: rr.prezzo
        }
        pz.prezzoUnitario = +(pz.prezzoNettoUnitario * (1 + iva)).toFixed(2),
        pz.prezzoTotale = +(pz.prezzoUnitario * rr.quantita).toFixed(2)
        pz.importoUnitario = +(pz.importoNettoUnitario * (1 + iva)).toFixed(2),
        pz.importoTotale = +(pz.importoUnitario * rr.quantita).toFixed(2)    
      }
      rigaDocumento.valore = _.merge(rigaDocumento.valore, pz)
    }
    testata.righe.push(rigaDocumento)
  }
  return await salvaDocumento(base, testata)
}

const salvaDocumento = async (base, dati) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'documenti'
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    let documento = { ...documento_default, ...dati }
    documento.editInfo = {
      dataCreazione: new Date()
    }
    await db.put(documento)
    const creato = await db.get(documento._id)
    await db.close()
    return creato
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

export default {
  creaOrdineWeb,
  salvaDocumento
}
