<template>
  <v-stepper v-model="stepper1" dense flat :alt-labels="altLabels">
    <v-stepper-header>
      <v-stepper-step step="1" editable :complete="clienteSelezionato != null">
        Scelta cliente    
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" editable>
        Scelta articoli
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="3" editable>
        <v-badge color="green" :content="numeroItems" offset-y="-1" :value="numeroItems">
          Carrello
        </v-badge>        
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <scelta-cliente :reset="triggerRefresh" @selezione="onClienteSelezionato"/>
        <v-btn color="primary" class="mt-3" @click="stepper1 = 2" :disabled="!clienteSelezionato">Scelta articoli</v-btn>            
      </v-stepper-content>
      <v-stepper-content step="2">
        <scelta-articoli :carrello="carrello" :cliente="clienteSelezionato" :reload="triggerRefresh" @quantita="onQuantitaArticoli"/>
        <v-btn color="default" class="mr-5 mt-3" @click="stepper1 = 1">Indietro</v-btn>
        <v-btn color="primary" class="mt-3" @click="stepper1 = 3">Carrello</v-btn>    
      </v-stepper-content>
      <v-stepper-content step="3">
        <carrello-ordine :carrello="carrello" :cliente="clienteSelezionato" :reload="triggerRefresh" @quantita="onQuantitaArticoli" @reset="onResetOrdine"/>
        <v-btn color="default" class="mr-5 mt-3" @click="stepper1 = 2">Indietro</v-btn>            
      </v-stepper-content>
    </v-stepper-items>         
  </v-stepper>
</template>

<script>
  import _ from 'lodash'
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import SceltaCliente from './components/sceltaCliente'
  import SceltaArticoli from './components/sceltaArticoli'
  import CarrelloOrdine from './components/carrelloOrdine'

  export default {
    components: {
      SceltaCliente,
      SceltaArticoli,
      CarrelloOrdine
    },
    mixins: [verificaLicenza],
    data () {
      return {
        stepper1: 1,
        clienteSelezionato: null,
        articoliSelezionati: [],
        carrello: [],
        triggerRefresh: 1
      }
    },
    async mounted() {
      // await this.verificaModuliLocali()
    },
    watch: {
      stepper1(value) {
/*         if (value === '4') {
          this.triggerRefresh++
        } */
      }
    },
    computed: {
      altLabels() {
        return ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)
      },
      numeroItems() {
        return _.sumBy(this.carrello, (o) => parseInt(o.appoggio.quantita))
      }
/*       lettureComplete() {
        return this.lettureSelezionate.length > 0
      } */
    },
    methods: {
      onResetOrdine() {
        this.carrello = []
        this.clienteSelezionato = null
        this.articoliSelezionati = []
        this.triggerRefresh++
        this.stepper1 = 1
      },
      onClienteSelezionato(clienti) {
        if (clienti.length > 0) {
          this.clienteSelezionato = clienti[0]
        } else {
          this.clienteSelezionato = null
        }
        // this.lettureSelezionate = elenco
      },
      onQuantitaArticoli(articolo) {
        let cr = this.carrello.find(x => x._id === articolo._id)
        if (cr) {
          if (articolo.appoggio.quantita === 0) {
            const idx = this.carrello.indexOf(cr)
            this.carrello.splice(idx, 1)
          } else {
            cr.appoggio.quantita = parseInt(articolo.appoggio.quantita)
          }
        } else {
          this.carrello.push(articolo)
        }
      }
    }
  }
</script>
