'use strict'
// TODO: Spostare su servizio generale in cloud
require('dotenv').config();
import PouchDB from 'pouchdb';
PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find

const getFilteredCustomers = async (base, filtri, opzioni) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'clienti'
  const urlDB = `${url}/${base}_${dbName}`;

  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let opz = {include_docs: true}

    const result = await db.allDocs(opz);
    await db.close()
    const elenco = result.rows.map(x => x.doc).filter(x => x && !x._id.startsWith('_'));

    let clienti = elenco
    if (filtri && filtri.isFornitore) {
      clienti = clienti.filter(x => x.amministrativi.fornitore)
    }
    if (filtri && filtri.isCliente) {
      clienti = clienti.filter(x => x.amministrativi.cliente)
    }
    if (filtri && filtri.indirizzo) {
      clienti = clienti.filter(x => x.ricerca.includes(filtri.indirizzo.toLoweCase()))
    }
    if (filtri && filtri.titolare) {
      clienti = clienti.filter(x => x.ricerca.includes(filtri.titolare.toLoweCase()))
    }    
    // if (!opzioni.allRecords) {
    //  return { rows: clienti, total_rows: clienti.length }
    //} else {
      const limit = opzioni.itemsPerPage
      const skip = (opzioni.page - 1) * opzioni.itemsPerPage 
      return { rows: _.take(_.drop(clienti, skip), limit), total_rows: clienti.length }
   // }
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

export default {
  getFilteredCustomers
}
