<template>
  <div>
    <v-data-table
      v-model="selected"
      :show-select="totaleClienti > 0"
      item-key="_id"
      :headers="headers"
      :items="clientiSorted"
      :options.sync="options"
      :server-items-length="totaleClienti"
      :loading="loading"
      class="elevation-1"
      @click:row="clickRow"
      @dblclick:row="dblclickRow"
      :disable-sort="true"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      single-select
      dense
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Clienti</v-toolbar-title>
      </v-toolbar>
      <filtri @ricerca="onRicerca"/>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="clientiSelected">
        <v-tabs dense v-model="tab[item._id]">
          <v-tab class="clientiSelected">Recapiti{{item.name}}</v-tab>
          <v-tab>Contatti</v-tab>
          <v-tab>Cards</v-tab>
<!--           <v-tab>Dati amministrativi</v-tab>
          <v-tab>Ordini</v-tab>
          <v-tab>Fatture</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab[item._id]">
          <v-tab-item>
            <!-- <recapiti :recapiti="item.recapiti" @update-address="updateAddress" @new-address="newAddress"/> -->
          </v-tab-item>
          <v-tab-item>
            <!-- <contatti :contatti="item.contatti" @update-contatto="updateContatto" @new-contatto="newContatto"/> -->
          </v-tab-item>
          <v-tab-item>
            <!-- <cards :idCliente="item._id"/> -->
          </v-tab-item>
          <v-tab-item></v-tab-item>
          <v-tab-item></v-tab-item>
          <v-tab-item>
            <!-- <fatture :recapiti="item.recapiti" @update-address="updateAddress" @new-address="newAddress"/> -->
          </v-tab-item>
        </v-tabs-items>
      </td>
    </template>    
    </v-data-table>
    <!-- <speed-dial /> -->
  </div>
</template>

<script>
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import { v4 as uuidv4 } from 'uuid'
  import { dynamicSortMultiple } from '@/components/helpers.js'
  import Filtri from './filtri'
  import clienti from '@/services/clientiService.js'
  
  export default {
    props: {
      reset: Number
    },
    components: {
      Filtri
    },
    mixins: [verificaLicenza],
    data () {
      return {
        totaleClienti: 0,
        clienti: [],
        selected: [],
        loading: true,
        options: {},
        headers: [
          { text: 'Codice', value: 'codice', align: 'start' },
          { text: 'Ragione sociale', value: 'ragioneSociale' },
          { text: 'Partita Iva', value: 'amministrativi.partitaIva' },
          { text: 'Indirizzo', value: 'c_indirizzo' },
          { text: 'Titolare', value: 'c_titolare' },
          { text: 'Actions', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }
        ],
        expanded: [],
        singleExpand: false,
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        tab: [],
        base: ''
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo Cliente' : 'Modifica Cliente'
      },
      customerSaved() {
        return this.$store.getters['clienti/saved']
      },
      refreshList() {
        return this.$store.getters['clienti/refresh']
      },
      clientiSorted() {
        const els = this.clienti
        const elSorted = els.sort(dynamicSortMultiple('ragioneSociale'))
        return elSorted
      },
      device() {
        return this.$store.getters['mobile/device']
      }
    },
    watch: {
      options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      async customerSaved(value) {
        if (value) {
          await this.save()
        }
      },
      async refreshList(value) {
        if (value) {
          await this.getDataFromApi()
          this.$store.commit('clienti/CLEAR_REFRESH')
        }
      },
      device(value) {
        this.base = value.base
      },
      selected(value) {
        this.$emit('selezione', this.selected)
      },
      reset(value) {
        this.selected = []
      } 
    },
    async mounted () {
      if (this.device) {
        this.base = this.device.base
      }      
      // await this.verificaModuliLocali()
      await this.getDataFromApi()
    },
    methods: {
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          this.options.allRecords = true
          if (!this.base) {
            const device = this.device
            this.base = device.base
          }
          const data = await clienti.getFilteredCustomers(this.base, this.filtri, this.options)
          this.clientiRaw = data && data.total_rows > 0 ? data.rows : []
          this.clienti = this.datiVisualizzazione(this.clientiRaw)
          this.totaleClienti = data.total_rows
        } catch(err) {
          this.clienti = []
          this.totaleClienti = 0
        } finally {
          this.loading = false
        }
      },
      async onNuovoCliente() {
        this.defaultItem = await clienti.getModello()
        if (this.defaultItem.recapiti && this.defaultItem.recapiti.length > 0) {
          this.defaultItem.recapiti[0].codice = uuidv4()
        }
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      editItem (item) {
        this.editedIndex = this.clienti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      duplicateItem(item) {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, item)
        this.editedItem._id = ''
        delete this.editedItem._rev
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      deleteItem (item) {
        this.editedIndex = this.clienti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.clienti.splice(this.editedIndex, 1)
        // TODO: ATTENZIONE VA VERIFICATO CHE NON ABBIA STORIA
        if (!await clienti.cancellaCliente(this.editedItem)) {
          this.dialogErrore = true
        }
        this.closeDelete()
      },

      close () {
        this.$store.commit('SET_RIGHT_DRAWER', false)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
        this.$store.commit('clienti/CLEAR_CLIENTE_SAVED')
        this.editedItem = this.$store.getters['clienti/cliente']
        if (this.editedIndex > -1) {
          this.editedItem = this.deleteDatiVisualizzazione(this.editedItem)
          Object.assign(this.clienti[this.editedIndex], this.editedItem)
          if (!await clienti.modificaCliente(this.editedItem)) {
            this.dialogErrore = true
            return
          }
        } else {
          this.clienti.push(this.editedItem)
          if (!await clienti.creaCliente(this.editedItem)) {
            this.dialogErrore = true
            return
          }
        }
        this.close()
        // va fatto il refresh della lista per prendere gli aggiornamenti
        await this.getDataFromApi()
      },
      erroreConfirm() {
        this.dialogErrore = false
      },
      clickRow(item, options) {

      },
      dblclickRow(event, options) {
        const item = options.item
        this.editedIndex = this.clienti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', true)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      datiVisualizzazione(clienti) {
        const elaborati = clienti.map(el => {
          const recapito = el.recapiti && el.recapiti.length > 0 ? el.recapiti.find(x => x.tipo.codice === 'principale') : null
          const indirizzo = recapito ? `${recapito.indirizzo}, ${recapito.localita} ${recapito.cap} - ${recapito.provincia}` : ''
          const contatto = el.contatti && el.contatti.length > 0 ? el.contatti.find(x => x.tipo === 'titolare') : null
          let titolare = ''
          if (contatto) {
            titolare = `${contatto.nome} ${contatto.cognome}`
            if (contatto.cellulare) {
              titolare += ` - cell: ${contatto.cellulare}`
            }
            if (contatto.email) {
              titolare += ` - email: ${contatto.email}`
            }            
          }
          return { ...el, c_recapito: recapito, c_indirizzo: indirizzo, c_contatto: contatto, c_titolare: titolare }
        }) 
        return elaborati
      },
      deleteDatiVisualizzazione(cliente) {
        let locCliente = {...cliente}
        // Eventuale aggiornamento dati modificati
        delete locCliente.c_recapito
        delete locCliente.c_indirizzo
        delete locCliente.c_contatto
        delete locCliente.c_titolare
        return locCliente
      },
      newAddress(idCliente, address) {

      },
      updateAddress(idCliente, address) {

      },
      newContatto(idCliente, contatto) {

      },
      updateContatto(idCliente, contatto) {

      },
      newCard(idCliente, card) {

      },
      updateCard(idCliente, card) {

      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.clientiSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }
</style>