<template>
  <div>
    <v-data-table
      item-key="_id"
      :headers="headers"
      :items="articoliSorted"
      :options.sync="options"
      :server-items-length="totaleArticoli"
      :loading="loading"
      class="elevation-1"
      :disable-sort="true"
      :footer-props="{
        itemsPerPageOptions: [10, 15, 50, 100]
      }"
      dense
    >    
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Articoli</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-autocomplete
          :items="magazzini"
          item-text="descrizione"
          item-value="codice"
          v-model="magazzinoGiacenza"
          label="Magazzino giacenza"
          :return-object="true"
          dense
          hide-details="auto"
          :outlined="true"
          @change="onChange"
        >
        </v-autocomplete>
      </v-toolbar>
      <filtri :base="base" :usaTagliaColore="usaTagliaColore" @ricerca="onRicerca"/>
    </template>
    <template v-slot:[`item.prezzoCliente`]="{ item }">
      {{ item.prezzo && item.prezzoCliente | euro }}
    </template>
    <template v-slot:[`item.magazzino.giacenza`]="{ item }">
      {{ getGiacenza(item) }}
    </template>    
    <template v-slot:[`item.infoPrezzo.prezzoListino`]="{ item }">
      {{ item.prezzo && item.infoPrezzo && item.infoPrezzo.prezzoListino | euro }}
    </template>
    <template v-slot:[`item.magazzino.prezzoAcquisto`]="{ item }">
      {{ item.magazzino && item.magazzino.prezzoAcquisto | dueDecimali }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="articoliSelected">
        <v-tabs dense v-model="tab[item._id]">
        </v-tabs>
        <v-tabs-items v-model="tab[item._id]"> 
        </v-tabs-items>
      </td>
    </template>
    <template v-slot:[`item.appoggio.quantita`]="{ item }">
      <v-row>
        <v-col cols="3">
          <v-btn x-small color="warning" @click="decQuantita(item)">-</v-btn>
        </v-col>
        <v-col cols="6">
          <v-edit-dialog
            :return-value.sync="item.appoggio.quantita"
            large
            persistent
            @save="saveQuantita(item)"
          >
            <div :class="testGiacenza(item) ? 'blue--text' : 'red--text'">{{ item.appoggio.quantita }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Quantità
              </div>
              <v-text-field
                v-model="item.appoggio.quantita"
                label="Edit"
                single-line
                type="number"
                autofocus
                :rules="[testQuantita(item) || 'Valore non valido']"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </v-col>
        <v-col cols="3">
          <v-btn x-small color="success" @click="incQuantita(item)">+</v-btn>
        </v-col>
      </v-row>
    </template>
    </v-data-table>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import { dynamicSortMultiple } from '@/components/helpers.js'
  import Filtri from './filtri'
  import prodotti from '@/services/prodottiService.js'
  import configurazioni from '@/services/configurazioniService.js'

  export default {
    props: {
      carrello: Array,
      cliente: Object
    },
    components: {
      Filtri
    },
    mixins: [verificaLicenza],
    data () {
      return {
        totaleArticoli: 0,
        articoli: [],
        selected: [],
        loading: true,
        options: {},
        headersDefault: [ // Caricato se non ci sono impostazioni diverse nel DB
          { text: 'Codice', value: 'codice', align: 'start' },
          { text: 'Descrizione', value: 'descrizione' },
          { text: 'Taglia', value: 'varianti.variante1.descrizione' },
          { text: 'Colore', value: 'varianti.variante2.descrizione' },
          { text: 'Marchio', value: 'marchio' },
          { text: 'Listino €', value: 'prezzo.L2.base', align: 'end' },
          { text: 'Prezzo €', name: 'prezzo', value: 'prezzo.L1.base', align: 'end' },
          { text: 'Acquisto €', value: 'magazzino.prezzoAcquisto', align: 'end' },
          { text: 'Giacenza', value: 'magazzino.giacenza', align: 'end' },
          { text: 'Prod/Rep', value: 'tipoItem', align: ' d-none d-xl-table-cell' },
          { text: 'Bene', value: 'tipoBene', align: ' d-none d-xl-table-cell' }
        ],
        headersBase: [
          { text: '', value: 'appoggio.dec', sortable: false, align: 'center' },
          { text: 'Acquisto', value: 'appoggio.quantita', sortable: false, align: 'end', width: "130px" },
          { text: '', value: 'appoggio.inc', sortable: false, align: 'center' },
          { text: '', value: 'data-table-expand' }
        ],
        headersBO: [],
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        expanded: [],
        singleExpand: false,
        tab: [],
        trigRefreshVendite: 0,
        trigRefreshMovimenti: 0,
        base: '',
        usaTagliaColore: false,
        soloGiacenzaPositiva: true,
        magazzini: [],
        magazzinoGiacenza: { codice: 'mag01' }
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo Articolo' : 'Modifica Articolo'
      },
      productSaved() {
        return this.$store.getters['prodotti/saved']
      },
      refreshList() {
        return this.$store.getters['prodotti/refresh']
      },
      articoliSorted() {
        const els = this.articoli
        const elSorted = els.length > 0 ? els.sort(dynamicSortMultiple('descrizione', 'varianti.variante2.descrizione', 'varianti.variante1.descrizione')) : []
        return elSorted
      },
      headers() {
        return this.headersBO.concat(this.headersBase)
      },
      device() {
        return this.$store.getters['mobile/device']
      }   
    },
    watch: {
      options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      async productSaved(value) {
        if (value) {
          await this.save()
        }
      },
      async refreshList(value) {
        if (value) {
          await this.getDataFromApi()
        }
      },
      device(value) {
        this.base = value.base
      },
      selected(value) {
        this.$emit('selezione', this.selected)
      },
      async cliente() {
        await this.getDataFromApi()
      }
    },
    async mounted () {
      if (!this.base) {
        const device = this.device
        this.base = device.base
      }
      const backoffice = await configurazioni.getConfigurazione(this.base, 'mobile', 'platform')
      this.headersBO = _.get(backoffice, 'impostazioni.prodotti.griglia.headers', this.headersDefault)
      this.usaTagliaColore = _.get(backoffice, 'impostazioni.usaTagliaColore', false)
      this.soloGiacenzaPositiva = _.get(backoffice, 'impostazioni.soloGiacenzaPositiva', true)

      const magazzini = await configurazioni.getConfigurazione(this.base, 'magazzino_magazzini', 'magazzini')
      this.magazzini = magazzini.filter(x => x.attivo && !x.temporaneo)
    },
    methods: {
      async onChange() {
        await this.getDataFromApi()
      },
      getGiacenza(item) {
        const giacenza =  _.get(item, `magazzino.giacenzaParziale.${this.magazzinoGiacenza.codice}.giacenza`, 0)
        return item.magazzino.giacenzaParziale ? giacenza : _.get(item, `magazzino.giacenza`, 0) 
      },
      testQuantita(item) {
        return item.appoggio.quantita >=0 && (item.appoggio.quantita <= this.getGiacenza(item) || !this.soloGiacenzaPositiva)
      },
      testGiacenza(item) {
        return item.appoggio.quantita === 0 || (item.appoggio.quantita <= this.getGiacenza(item))
      },      
      saveQuantita (item) {
        if ((item.appoggio.quantita > this.getGiacenza(item)) && this.soloGiacenzaPositiva) {
          item.appoggio.quantita = this.getGiacenza(item)
        }       
        if (item.appoggio.quantita < 0) {
          item.appoggio.quantita = 0
        }
        this.$emit('quantita', item)
      },
      decQuantita(item) {
        if (item.appoggio.quantita > 0) {
          item.appoggio.quantita--
        }
        this.saveQuantita(item)
      },
      incQuantita(item) {
        // if (item.appoggio.quantita < (item.magazzino.giacenza || 0) || !this.soloGiacenzaPositiva) {
        if (item.appoggio.quantita < this.getGiacenza(item) || !this.soloGiacenzaPositiva) {
          item.appoggio.quantita++
        }
        this.saveQuantita(item)
      },
      async onRicerca(filtri) {
        this.filtri = filtri
        this.filtri.tipoItem = 'PRODOTTO' // verificare come e se mettere
        this.options.page = 1
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          const condizioni = this.getCondizioni(this.cliente) // condizioni di vendita per il cliente, usato per calcolo prezzo prodotti
          const filtri = { ...this.filtri, magazzinoGiacenza: this.magazzinoGiacenza.codice }
          const data = await prodotti.prod_list_by_filter(this.base, filtri, this.options, condizioni)
          let articoli = data && data.total_rows > 0 ? data.rows.map(x => x.doc) : []
          articoli = articoli.map(x => {
            return { ...x, appoggio: { quantita: 0 }}
          })
          for (let c of this.carrello) {
            let p = articoli.find(x => x._id === c._id)
            if (p) {
              p.appoggio.quantita = c.appoggio.quantita
            }
          }
          this.articoli = articoli
          this.totaleArticoli = data.total_rows
        } catch(err) {
          this.articoli = []
          this.totaleArticoli = 0
        } finally {
          this.loading = false
        }
      },
      getCondizioni(cliente) {
        // Aggiunta anche su server clienti
        const listino = _.get(cliente, 'amministrativi.listino.codice', '')
        const sconti = _.get(cliente, 'amministrativi.sconti', null)
        let categoriaSconti = _.get(cliente, 'amministrativi.categoriaSconti', null) // verificare dove mettere e come impostare
        if (categoriaSconti) {
          categoriaSconti = '_LT_' + categoriaSconti
        }        
        return {
          codice: '_L_' + cliente.codice,
          categoriaSconti: categoriaSconti,
          listino: listino,
          sconti: sconti
          // Aggiungere eventuali promozioni
        }
      },
      erroreConfirm() {
        this.dialogErrore = false
      },
      annullaSelezione() {
        while(this.selected.length > 0) { this.selected.pop() }
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  {
    box-shadow: none !important;
  }
    td.articoliSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }
</style>
