var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.articoliSorted,"options":_vm.options,"server-items-length":_vm.totaleArticoli,"loading":_vm.loading,"disable-sort":true,"footer-props":{
      itemsPerPageOptions: [10, 15, 50, 100]
    },"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Articoli")]),_c('v-spacer'),_c('v-spacer'),_c('v-autocomplete',{attrs:{"items":_vm.magazzini,"item-text":"descrizione","item-value":"codice","label":"Magazzino giacenza","return-object":true,"dense":"","hide-details":"auto","outlined":true},on:{"change":_vm.onChange},model:{value:(_vm.magazzinoGiacenza),callback:function ($$v) {_vm.magazzinoGiacenza=$$v},expression:"magazzinoGiacenza"}})],1),_c('filtri',{attrs:{"base":_vm.base,"usaTagliaColore":_vm.usaTagliaColore},on:{"ricerca":_vm.onRicerca}})]},proxy:true},{key:"item.prezzoCliente",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.prezzo && item.prezzoCliente))+" ")]}},{key:"item.magazzino.giacenza",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGiacenza(item))+" ")]}},{key:"item.infoPrezzo.prezzoListino",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.prezzo && item.infoPrezzo && item.infoPrezzo.prezzoListino))+" ")]}},{key:"item.magazzino.prezzoAcquisto",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dueDecimali")(item.magazzino && item.magazzino.prezzoAcquisto))+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDataFromApi}},[_vm._v("Ricarica")])]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"articoliSelected",attrs:{"colspan":headers.length}},[_c('v-tabs',{attrs:{"dense":""},model:{value:(_vm.tab[item._id]),callback:function ($$v) {_vm.$set(_vm.tab, item._id, $$v)},expression:"tab[item._id]"}}),_c('v-tabs-items',{model:{value:(_vm.tab[item._id]),callback:function ($$v) {_vm.$set(_vm.tab, item._id, $$v)},expression:"tab[item._id]"}})],1)]}},{key:"item.appoggio.quantita",fn:function(ref){
    var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"x-small":"","color":"warning"},on:{"click":function($event){return _vm.decQuantita(item)}}},[_vm._v("-")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-edit-dialog',{attrs:{"return-value":item.appoggio.quantita,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item.appoggio, "quantita", $event)},"update:return-value":function($event){return _vm.$set(item.appoggio, "quantita", $event)},"save":function($event){return _vm.saveQuantita(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Quantità ")]),_c('v-text-field',{attrs:{"label":"Edit","single-line":"","type":"number","autofocus":"","rules":[_vm.testQuantita(item) || 'Valore non valido']},model:{value:(item.appoggio.quantita),callback:function ($$v) {_vm.$set(item.appoggio, "quantita", $$v)},expression:"item.appoggio.quantita"}})]},proxy:true}],null,true)},[_c('div',{class:_vm.testGiacenza(item) ? 'blue--text' : 'red--text'},[_vm._v(_vm._s(item.appoggio.quantita))])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.incQuantita(item)}}},[_vm._v("+")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }