var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('riepilogo-carrello',{attrs:{"cliente":_vm.cliente,"carrello":_vm.carrello,"disabled":_vm.ordineInviato,"reset":_vm.triggerReset},on:{"invia":_vm.inviaOrdine},scopedSlots:_vm._u([{key:"nuovoOrdine",fn:function(){return [(_vm.ordineInviato)?_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.onNuovoOrdine}},[_vm._v("Nuovo Ordine")]):_vm._e()]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.articoliSorted,"options":_vm.options,"loading":_vm.loading,"disable-sort":true,"footer-props":{
        itemsPerPageOptions: [10, 15, 50, 100]
      },"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Riepilogo carrello")])],1)]},proxy:true},{key:"item.prezzo.L1.base",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.prezzo && item.prezzo.L1 && item.prezzo.L1.base))+" ")]}},{key:"item.infoPrezzo.prezzoListino",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.prezzo && item.infoPrezzo && item.infoPrezzo.prezzoListino))+" ")]}},{key:"item.prezzoCliente",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.prezzoCliente))+" ")]}},{key:"item.importo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euro")(item.prezzoCliente * item.appoggio.quantita))+" ")]}},{key:"item.appoggio.quantita",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"x-small":"","color":"warning"},on:{"click":function($event){return _vm.decQuantita(item)}}},[_vm._v("-")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-edit-dialog',{attrs:{"return-value":item.appoggio.quantita,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item.appoggio, "quantita", $event)},"update:return-value":function($event){return _vm.$set(item.appoggio, "quantita", $event)},"save":function($event){return _vm.saveQuantita(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Quantità ")]),_c('v-text-field',{attrs:{"label":"Edit","single-line":"","type":"number","autofocus":"","rules":[_vm.testQuantita(item) || 'Valore non valido']},model:{value:(item.appoggio.quantita),callback:function ($$v) {_vm.$set(item.appoggio, "quantita", $$v)},expression:"item.appoggio.quantita"}})]},proxy:true}],null,true)},[_c('div',{class:_vm.testGiacenza(item) ? 'blue--text' : 'red--text'},[_vm._v(_vm._s(item.appoggio.quantita))])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.incQuantita(item)}}},[_vm._v("+")])],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" OK ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }