<template>
  <div>
    <riepilogo-carrello :cliente="cliente" :carrello="carrello" :disabled="ordineInviato" :reset="triggerReset" @invia="inviaOrdine">
      <template v-slot:nuovoOrdine>
        <v-btn v-if="ordineInviato" color="info" @click="onNuovoOrdine">Nuovo Ordine</v-btn>
      </template>
    </riepilogo-carrello>
    
    <v-data-table
      item-key="_id"
      :headers="headers"
      :items="articoliSorted"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      :disable-sort="true"
      :footer-props="{
        itemsPerPageOptions: [10, 15, 50, 100]
      }"
      dense
    >    
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Riepilogo carrello</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:[`item.prezzo.L1.base`]="{ item }">
      {{ item.prezzo && item.prezzo.L1 && item.prezzo.L1.base | euro }}
    </template>
    <template v-slot:[`item.infoPrezzo.prezzoListino`]="{ item }">
      {{ item.prezzo && item.infoPrezzo && item.infoPrezzo.prezzoListino | euro }}
    </template>
    <template v-slot:[`item.prezzoCliente`]="{ item }">
      {{ item.prezzoCliente | euro }}
    </template> 
    <template v-slot:[`item.importo`]="{ item }">
      {{ item.prezzoCliente * item.appoggio.quantita | euro }}
    </template>     
<!--     <template v-slot:[`item.appoggio.dec`]="{ item }">
      <v-btn x-small color="warning" @click="decQuantita(item)">-</v-btn>
    </template> -->
    <template v-slot:[`item.appoggio.quantita`]="{ item }">
      <v-row>
        <v-col cols="3">
          <v-btn x-small color="warning" @click="decQuantita(item)">-</v-btn>
        </v-col>
        <v-col cols="6">
          <v-edit-dialog
            :return-value.sync="item.appoggio.quantita"
            large
            persistent
            @save="saveQuantita(item)"
          >
            <div :class="testGiacenza(item) ? 'blue--text' : 'red--text'">{{ item.appoggio.quantita }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Quantità
              </div>
              <v-text-field
                v-model="item.appoggio.quantita"
                label="Edit"
                single-line
                type="number"
                autofocus
                :rules="[testQuantita(item) || 'Valore non valido']"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </v-col>
        <v-col cols="3">
          <v-btn x-small color="success" @click="incQuantita(item)">+</v-btn>
        </v-col>
      </v-row>
    </template>
<!--     <template v-slot:[`item.appoggio.inc`]="{ item }">
      <v-btn x-small color="success" @click="incQuantita(item)">+</v-btn>
    </template> -->
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn  color="blue" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>    
  </div>
</template>

<script>
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import { dynamicSortMultiple } from '@/components/helpers.js'
  import configurazioni from '@/services/configurazioniService.js'
  import documenti from '@/services/documentiService.js'
  import scontrini from '@/services/scontriniService.js'
  import RiepilogoCarrello from './riepilogo'

  export default {
    props: {
      carrello: Array,
      cliente: Object
    },
    components: {
      RiepilogoCarrello
    },
    mixins: [verificaLicenza],
    data () {
      return {
        totaleArticoli: 0,
        articoli: [],
        selected: [],
        loading: false,
        options: {},
        headersDefault: [ // Caricato se non ci sono impostazioni diverse nel DB
          { text: 'Codice', value: 'codice', align: 'start' },
          { text: 'Descrizione', value: 'descrizione' },
          { text: 'Taglia', value: 'varianti.variante1.descrizione' },
          { text: 'Colore', value: 'varianti.variante2.descrizione' },
          { text: 'Marchio', value: 'marchio' },
          { text: 'Listino €', value: 'prezzo.L2.base', align: 'end' },
          { text: 'Prezzo €', name: 'prezzo', value: 'prezzo.L1.base', align: 'end' },
          { text: 'Importo', value: 'importo', align: 'end' },
          { text: 'Prod/Rep', value: 'tipoItem', align: ' d-none d-xl-table-cell' },
          { text: 'Bene', value: 'tipoBene', align: ' d-none d-xl-table-cell' }
        ],
        headersBase: [
          { text: '', value: 'appoggio.dec', sortable: false, align: 'center' },
          { text: 'Acquisto', value: 'appoggio.quantita', sortable: false, align: 'end', width: "130px" },
          { text: '', value: 'appoggio.inc', sortable: false, align: 'center' },
          { text: '', value: 'data-table-expand' }
        ],
        headersBO: [],
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        expanded: [],
        singleExpand: false,
        tab: [],
        trigRefreshVendite: 0,
        trigRefreshMovimenti: 0,
        base: '',
        impostazioniOrdine: null,
        ordiniDefault: {
          documento: {
            crea: true
          },
          parcheggio: {
            crea: true,
            zona: 3,
            righe: 7,
            colonne: 7
          },
          tipo: {
            codice: 'ordineClienteWeb',
            descrizione: 'Ordine cliente da Mobile'
          }
        },
        snackbar: false,
        snackColor: '',
        snackText: 'L\'ordine è stato inviato con successo !',
        timeout: 4000,
        ordineInviato: false,
        triggerReset: 1,
        soloGiacenzaPositiva: true
      }
    },
    computed: {
      articoliSorted() {
        const els = this.articoli
        const elSorted = els.length > 0 ? els.sort(dynamicSortMultiple('descrizione', 'varianti.variante2.descrizione', 'varianti.variante1.descrizione')) : []
        return elSorted
      },
      headers() {
        return this.headersBO.concat(this.headersBase)
      },
      device() {
        return this.$store.getters['mobile/device']
      }
    },
    watch: {
       options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      device(value) {
        this.base = value.base
      }
    },
    async mounted () {
      if (!this.base) {
        const device = this.device
        this.base = device.base
      }
      const backoffice = await configurazioni.getConfigurazione(this.base, 'mobile', 'platform')
      this.headersBO = _.get(backoffice, 'impostazioni.carrello.griglia.headers', this.headersDefault)
      this.impostazioniOrdine = _.get(backoffice, 'impostazioni.ordini.creazione', this.ordiniDefault)      
      this.soloGiacenzaPositiva = _.get(backoffice, 'impostazioni.soloGiacenzaPositiva', true)
    },
    methods: {
      onNuovoOrdine() {
        this.ordineInviato = false
        this.triggerReset++
        this.$emit('reset')
      },
      async inviaOrdine(custom) {
        /*
          In base alle impostazioni:
          1) Crea documento Ordine cliente --> Icona Ordini cliente
          2) Inserisce su park libero --> da backoffice / cassa posso recuperare il contenuto del park e creare un documento
        */
        this.ordineInviato = true
        const imp = this.impostazioniOrdine
        let ordineOK = 0
        if (_.get(imp, 'documento.crea', false)) {
          const ordine = await this.inviaOrdineDocumento(imp, custom)
          custom.ordineId = ordine._id
          custom.ordineData = ordine.data
          ordineOK += (ordine ? 2 : -10)
        }
        if (_.get(imp, 'parcheggio.crea', false)) {
          const parcheggio = await this.inviaOrdinePark(imp, custom)
          ordineOK += (parcheggio ? 4 : -20)
        }
        if (ordineOK > 0) {
          this.snackColor = 'success'
          this.snackText = 'L\'ordine è stato inviato con successo !'
          this.snackbar = true
        } else {
          this.snackColor = 'red accent-2'
          this.snackText = 'Errore invio ordine !'
          this.snackbar = true
        }
      },
      async inviaOrdineDocumento(imp, custom) {
        let righe = []
        for (let p of this.carrello) {
          const riga = {
            codice: p._id,
            codiceProdotto: p.codice,
            barcode: p.ricercaBarcode,
            variante1: _.get(p, 'varianti.variante1.codice', ''),
            variante2: _.get(p, 'varianti.variante2.codice', ''),
            quantita: p.appoggio.quantita,
            descrizione: `${p.descrizione} ${_.get(p, 'varianti.variante1.descrizione', '')} ${_.get(p, 'varianti.variante2.descrizione', '')}`.trim(),
            prezzoUnitario: +p.prezzoCliente.toFixed(2),
            prezzo: +(p.prezzoCliente * p.appoggio.quantita).toFixed(2),
            unitaMisura: _.get(p, 'magazzino.unitaMisura', ''),
            iva: _.get(p, 'datiFiscali.aliquoteIva[0]', null),
            tipo: 'prodotto',
            tipoBene: 'PRODOTTO',
            repartoFiscale: _.get(p, 'datiFiscali.reparto.stampante', ''),
            prezzoAcquisto: _.get(p, 'magazzino.prezzoAcquisto', ''),
            infoPrezzo: p.infoPrezzo
          }
          righe.push(riga)
        }
        let payload = { // eventuali valori da aggiungere
          /*           dataOrdine: new Date(),
          tipo: imp.tipo,
          cliente: {
            codice: this.cliente.codice,
            ragioneSociale: this.cliente.ragioneSociale
          } */
        }
        payload = _.merge(payload, custom)
        return await documenti.creaOrdineWeb(this.base, imp.tipo.codice, this.cliente, righe, payload)
      },
      async inviaOrdinePark(imp, custom) {     
        const pk = imp.parcheggio
        const posizione = await this.calcPosizione(pk.zona, pk.righe, pk.colonne)
        if (posizione) {
          let righe = []
          for (let p of this.carrello) {
            const riga = {
              codice: p._id,
              codiceProdotto: p.codice,
              barcode: p.ricercaBarcode,
              variante1: _.get(p, 'varianti.variante1.codice', ''),
              variante2: _.get(p, 'varianti.variante2.codice', ''),
              quantita: p.appoggio.quantita,
              descrizione: `${p.descrizione} ${_.get(p, 'varianti.variante1.descrizione', '')} ${_.get(p, 'varianti.variante2.descrizione', '')}`.trim(),
              prezzoUnitario: +p.prezzoCliente.toFixed(2),
              prezzo: (p.prezzoCliente * p.appoggio.quantita).toFixed(2),
              aliquota: _.get(p, 'datiFiscali.aliquoteIva[0].valore', ''),
              tipo: 'prodotto',
              tipoBene: 'PRODOTTO',
              repartoFiscale: _.get(p, 'datiFiscali.reparto.stampante', ''),
              prezzoAcquisto: _.get(p, 'magazzino.prezzoAcquisto', '')
            }
            righe.push(riga)
          }
          let payload = {
            dataOrdine: new Date(),
            tipo: imp.tipo,
            cliente: {
              codice: this.cliente.codice,
              ragioneSociale: this.cliente.ragioneSociale
            }
          }
          payload = _.merge(payload, custom)
          return await scontrini.creaScontrino(this.base, { righe }, { zona: String(pk.zona), posizione: posizione, payload: payload })
        } else {
          this.snackColor = 'red accent-2'
          this.snackText = 'Errore invio ordine, slot parcheggio esauriti !'
          this.snackbar = true
        }
      },
      async calcPosizione(zona, righe, colonne) {
        let posizioni = []
        for (let r = 1; r <= righe; r++) {
          for (let c = 1; c <= colonne; c++) {
            posizioni.push(`${zona}.${r}.${c}`)
          }
        }
        let parcheggiati = await scontrini.getParcheggiati(this.base)
        const occupati = parcheggiati.length > 0 ? parcheggiati.map(x => x.parcheggio.posizione) : []
        const liberi = _.difference(posizioni, occupati)
        return liberi.length > 0 ? liberi[0] : ''
      },
      testQuantita(item) {
        // return item.appoggio.quantita >=0 && item.appoggio.quantita <= item.magazzino.giacenza
        return item.appoggio.quantita >=0 && (item.appoggio.quantita <= item.magazzino.giacenza || !this.soloGiacenzaPositiva)
      },
      testGiacenza(item) {
        return item.appoggio.quantita === 0 || (item.appoggio.quantita <= item.magazzino.giacenza)
      },
      saveQuantita (item) {
        // riportare solo i valori necessari
        if ((item.appoggio.quantita > item.magazzino.giacenza) && this.soloGiacenzaPositiva) {
          item.appoggio.quantita = item.magazzino.giacenza
        }
        if (item.appoggio.quantita < 0) {
          item.appoggio.quantita = 0
        }
        this.$emit('quantita', item)
      },
      decQuantita(item) {
        if (item.appoggio.quantita > 0) {
          item.appoggio.quantita--
        }
        this.saveQuantita(item)
      },
      incQuantita(item) {
        if (item.appoggio.quantita < (item.magazzino.giacenza || 0) || !this.soloGiacenzaPositiva) {
          item.appoggio.quantita++
        }
        this.saveQuantita(item)
      },
      async getDataFromApi () {
        try {
          this.loading = true
          this.articoli = this.carrello
          this.totaleArticoli = this.carrello.length
        } catch(err) {
          this.articoli = []
          this.totaleArticoli = 0
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  {
    box-shadow: none !important;
  }
    td.articoliSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }
</style>
