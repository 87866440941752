import licenze from '@/services/licenzeService.js'

// TODO: Va verificata la licenza per il backoffice --> audience: backoffice
export const verificaLicenza = {
  data() {
    return {
      token: ''
    }
  },
  methods: {
    async gestioneToken() {
      this.token = await licenze.getToken()
      if (this.token) {
        console.log('Letto token')
        // Aggiorna il token trovato
        localStorage.setItem('token_bk', JSON.stringify(this.token))
      } else {
        console.log('Token da localStorage')
        if (localStorage.getItem('token_bk')) {
          try {
            this.token = JSON.parse(localStorage.getItem('token_bk'))
          } catch (e) {
            localStorage.removeItem('token_bk')
            this.token = ''
          }
        }
      }
      const verifica = await licenze.verifyToken(this.token)
      console.log(verifica)
      switch (verifica.stato) {
        case 'token.scaduto': // blocca l'applicazione
          this.$router.push('/token')
          break
        case 'token.inScadenza': // segnala di ricollegare
          this.context.displayMessage = `Ricordati di collegare la rete... Hai solo ${verifica.oreToken} ${verifica.oreToken === 1 ? 'ora' : 'ore'} per farlo`
          break
        case 'licenza.scaduta': // blocca l'applicazione
          this.$router.push('/licenza')
          break
        case 'licenza.inScadenza': // segnala di aggiornare la licenza
          this.context.displayMessage = `Attenzione! La licenza scade tra ${verifica.giorniLicenza} ${verifica.giorniLicenza === 1 ? 'giorno' : 'giorni'}...`
          break
        default:
          break
      }
      this.$store.commit('SET_TOKEN', this.token)
      // prodotti.setToken(this.token) // Todo: Distribuire ai vari Services, per adesso fatto solo su prodotti
    }
  }
}
